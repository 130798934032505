.navbar{
    padding: 10px 24px;
    text-align: right;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
    position: sticky;
    top: 0;
    left: 0;
    background: white;
}

.navbar-item{
    background-color: white;
    border-radius: 10px;
    color: black;
    border: 1px solid black;
    padding: 5px 10px;
    margin-right: 10px;
    transition: all ease-in-out 0.2s;
}

input{
    height:49px;
    border: 1px solid rgb(228, 229, 234);
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding:10px 15px;
    box-sizing: border-box;
}

.badge {
    position: absolute;
    top: -3px;
    right: 0;
}

.navbar-item:hover{
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;
}

.sidebar-item-default{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: none;
    border-left: 4px solid #F7F8FA;
    border-radius: 4px;
    padding-left: 10px;
}

.sidebar-item-current{
    border-left: 4px solid #4529FA;
    border-radius: 4px;
    padding-left: 10px;

    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sidebar-item-finish{
    color: #553CFA;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.default-text{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sidebar{
    border-right: 1px solid #EBEBEB;
    background: #fff;
    /* background: black; */
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
}

.bot-type{
    width: 48%;
    border-radius: 10px;
    padding: 24px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.bot-type:hover{
    cursor: pointer;
}


.bot-type-unselected{
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
}

.bot-type-selected{
    border: 1px solid var(--green-31-a-133, #4529FA);
    background: rgba(69, 41, 250, 0.02);
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    border-radius: 10px;
}

.bot-type h1{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.bot-type h2{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.soon-badge{
    margin-left: 20px;
    border-radius: 99px;
    background: #1C274C;

    color: var(--white-ffffff, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    padding: 5px 15px;
}

.next-button{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #4529FA;

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 40px;

    margin-top: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.prev-button{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: white;

    color: #1C274C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 40px;

    margin-top: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
}

.form-field{
    border-radius: 10px;
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);

    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 20px;
}

.chatbotdev-header{
    background: #F7F8FA;
    padding: 10px;
}

.chatbotdev{
    border-left: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FCFDFD;

    height: calc(100vh - 50px);
}
.chatbotdev-title{
    color: var(--main-1-c-274-c, #1C274C);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.chatbotdev-badge{
    margin-left: 10px;
    border-radius: 70px;
    background: var(--yellow-ffe-0-b-3, #FFE0B3);

    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 3px 15px;
}

.chatbotdev-more{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding: 3px 10px;
}

.chat-input{
    border: none;
    padding: 10px 15px;
    flex: 1;
}

.chat-send{
    border-radius: 4px;
    background: var(--main-12-e-4-e-5-ea, #E4E5EA);
    margin: 10px;
    padding: 5px 10px;
}

.chatbotdev-template{
    margin: 30px;
    padding: 12px 24px;
    border-radius: 8px;
    background: #EAEEF4;

    color: var(--main-1-c-274-c, #1C274C);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.prompt-template-editor{
    border-radius: 10px;
    border: 1px solid #F1F2F4;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
}

.prompt-template-editor-header{
    padding: 20px 25px;

    color: var(--main-1-c-274-c, #1C274C);

    /* Title h3 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    border-bottom: 1px solid #F1F2F4;
}

.prompt-template-editor-body{
    border: none;
    width: 100%;
    padding: 20px 25px;

    color: var(--main-873-a-4363, #3A4363);

    background: #FBFBFC;

    /* Regular TItle */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.edit-template-button{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);

    padding: 5px 10px;
}

.upload-document{
    border-radius: 10px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
}

.select-file-button{
    border-radius: 5px;
    border: 1px solid var(--main-38-a-9-adbb, #A9ADBB);
    background: var(--white-ffffff, #FFF);

    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.knowledge-basis{
    fill: #FFF;
    stroke-width: 1px;
    stroke: rgba(28, 39, 76, 0.06);
    filter: drop-shadow(0px 2px 4px rgba(28, 39, 76, 0.07));
}

.knowledge-base{
    border-bottom: 1px solid #EAEAEA;
    padding: 12px 15px;
}

.knowledge-basis-header{
    background: #F7F8FA;
    padding: 10px 20px;
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
}

.knowledge-base-attach{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 20%;
}

.knowledge-base-name-title{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.knowledge-base-name-desc{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.knowledge-base-status-desc{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.deploy-env{
    padding: 21px 24px;
    border-radius: 10px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    margin-bottom: 20px;
}

.deploy-pro-env{
    border-left: 10px solid #95D696;
}

.deploy-dev-env{
    border-left: 10px solid rgba(255, 153, 0, 0.30);
}

.deploy-env-title{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.deploy-badge{
    border-radius: 70px;
    background: #C7ECC8;
    padding: 8px 12px;
}

.deploy-text-default{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.deploy-url{
    border-radius: 10px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);

    padding: 10px 15px;
}

.deploy-history-type{
    padding: 5px 12px;
    border-radius: 70px;

    color: #1A1C21;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.deploy-history-prod{
    background: #C7ECC8;
}

.deploy-history-dev{
    background: #FFE0B3;
}

.deploy-history-id{
    color: var(--blue-0053-c-9, #0053C9);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-right: 10px;
}

.deploy-history-copy-link{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 10px;
    border-right: 1px solid rgba(26, 28, 33, 0.54);

    margin-right: 10px;
}

.deploy-history-copy-js{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat-container{
    height: calc(100vh - 300px);
    overflow-y: auto;
}

.chat-msg{
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 15px;
}

.chat-msg img{
    border-radius: 50%;
    margin-right: 20px;
}

.user-msg{
    background-color: lightgreen;
    color: white;
    border: 1px solid lightgreen;
}

.bot-msg{
    background-color: lightblue;
    color: white;
    border: 1px solid lightblue;
}

.copy-code:hover{
    color: blue;
    cursor: pointer;
}

.chatbot-production-title{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.chatbot-production-alert ~ .chatbot-production-desc{
    visibility: hidden;
}
/* .chatbot-production-alert:hover ~ .chatbot-production-desc{
    visibility: visible;
} */

.chatbot-production-expand{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding: 6px 8px;
}
.chatbot-production-expand-text{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chatbot-production-powered{
    color: var(--main-38-a-9-adbb, #A9ADBB);
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chatbot-production-powered ~ .chatbot-production-powered-desc{
    visibility: hidden;
}
/* .chatbot-production-powered:hover ~ .chatbot-production-powered-desc{
    visibility: visible;
} */

.chatbot-production-desc{
    padding: 16px 16px;
    position: fixed;
    top: 50px;
    left: 10px;
    width: 220px;
    border: 1px solid #E4E5EA;
    background-color: white;
    border-radius: 10px;

    font-size: 14px;
}

.chatbot-production-desc::after{
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 82%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}

.chatbot-production-powered-desc{
    padding: 16px 16px;
    position: fixed;
    top: 50px;
    right: 10px;
    width: 220px;
    border: 1px solid #E4E5EA;
    background-color: white;
    border-radius: 10px;

    font-size: 14px;
}

.chatbot-production-powered-desc::after{
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 82%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}

.chatbot-production-powered-link{
    color: var(--blue-0053-c-9, #0053C9);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.chatbot-production-send-text-active{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.chatbot-production-send-text-inactive{
    color: var(--main-54848-a-9-e, #848A9E);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.user-chat-msg{
    padding: 5px 20px;
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bot-chat-msg{
    background-color: #F1F4FC;
    padding: 20px;
    margin: 14px;
    border-radius: 10px;
}

.bot-chat-msg p{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-align: left;
}

.bot-type-select{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding: 15px;

    color: #0E1325;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    /* -webkit-appearance: none;
    width: 300px; */

}

.knowledge-base-type-selected{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 5px solid #1C274C;
    padding-bottom: 4px;
}

.knowledge-base-type-unselected{
    color: var(--main-1-c-274-c, #848A9E);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 5px solid white;
    padding-bottom: 4px;
}

.existing-knowledge-bases{
    border-radius: 20px;
}

.existing-knowledge-bases-header{
    background: #F7F8FA;
    padding: 10px 0px;

    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.existing-knowledge-bases-item{
    border-bottom: 1px solid #EAEAEA;
    padding: 15px 0px;
}

.tooltip::after{
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.bot-knowledge-base-type-selected, .bot-knowledge-base-integration-selected{
    border-radius: 4px;
    border: 2px solid var(--green-31-a-133, #31A133);
    background: #F2F9F2;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
}

.bot-knowledge-base-type-selected h1, .bot-knowledge-base-integration-selected h1{
    overflow: hidden;
    color: var(--main-1-c-274-c, #1C274C);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.bot-knowledge-base-type-selected h2, .bot-knowledge-base-integration-selectedh2{
    overflow: hidden;
    color: var(--main-1-c-274-c, #1C274C);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bot-knowledge-base-type-unselected, .bot-knowledge-base-integration-unselected{
    border-radius: 4px;
    border: 2px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    opacity: 0.75;
    background: #FFF;
}


.bot-knowledge-base-type-unselected h1, .bot-knowledge-base-integration-unselected h1{
    overflow: hidden;
    color: var(--main-1-c-274-c, #1C274C);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.bot-knowledge-base-type-unselected h2, .bot-knowledge-base-integration-unselected h2{
    overflow: hidden;
    color: var(--main-1-c-274-c, #1C274C);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.textarea{
    border-radius: 10px;
    border: 1px solid #F1F2F4;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
}

.textarea-header{
    color: var(--main-1-c-274-c, #1C274C);

/* Title h3 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 20px;
}

.textarea-header ~textarea{
    background-color: #FBFBFC
}

.skip-button{
    color: var(--main-54848-a-9-e, #848A9E);

    /* Regular TItle */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
}

.existing-base-table-title{
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.existing-base-table-content{
    color: var(--main-873-a-4363, #1C274C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.api-deploy-item{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.06);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding: 9px 14px;

    color: rgba(28, 39, 76, 0.70);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.api-deploy-copyIcon{
    border-radius: 4px;
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding: 7px 6px;
}

.test-api-widget{
    border-radius: 4px;
    border: 1px solid #F1F2F4;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
}

.api-deploy-print{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: JetBrains Mono;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 300px;
    overflow: auto;
}

.underline-button{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;

    margin-top: 20px;
}

.bot-list-header{
    background: var(--neutral-200, #F1F3F9);
}

.bot-list-header input{
    margin: 10px 8px;
}

.bot-list-header p{
    padding: 13px 8px;
    color: var(--text-icon-primary-black, #1D2433);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.28px;
    text-transform: capitalize;
}

.bot-list-item-odd{
    background: #FFF;
}

.bot-list-item-odd input{
    margin: 10px 8px;
}

.bot-list-item-odd p{
    padding: 13px 8px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.28px;
    text-transform: capitalize;
}

.bot-list-item-even{
    background: var(--neutral-200, #F1F3F9);
}

.bot-list-item-even input{
    margin: 10px 8px;
}

.bot-list-item-even p{
    padding: 13px 8px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.28px;
    text-transform: capitalize;
}

.bot-draft{
    color: #E28600;
}

.bot-active{
    color: var(--green-31-a-133, #31A133);
}

.console-output-item{
    border-top: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
    box-shadow: -2px 0px 10px 0px rgba(28, 39, 76, 0.06);
    height: 250px;
}

.console-output-text-enable{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;

    border-bottom: 3px solid var(--main-1-c-274-c, #1C274C);;
}

.console-output-text-disable{
    color: var(--main-54848-a-9-e, #848A9E);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.console-output-btn{
    border-radius: 4px;
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding: 7px 10px;

    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.output-text{
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    border-radius: 10px;
    padding: 10px 10px;
    font-size: 12px;
    min-height: 50px;
}

.plugin-item{
    padding: 20px;
    border-radius: 10px;
    border: 2px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
}

.plugin-item-active{
    border-radius: 10px;
    border: 2px solid #4529FA;
    background: rgba(69, 41, 250, 0.02);
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    padding: 20px;
}

.plugin-item-active1::after{
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #4529FA transparent transparent transparent;
}

.plugin-utils{
    border-radius: 10px;
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FBFBFF;
    padding: 10px 20px;
}

.existing-plugin-item{
    width: 240px;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
    margin: 10px;
    cursor: pointer;
}

.existing-plugin-item-active{
    width: 240px;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #4529FA;
    background: rgba(69, 41, 250, 0.02);
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    margin: 10px;
    cursor: pointer;
}

.existing-plugin-item p{
    height: 110px;
}
.existing-plugin-item-active p{
    height: 110px;
}

.previous-menu{
    border-radius: 4px;
    background: #F8F8F9;

    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.production-chatbot-section{
    right: 40px;
    bottom: 70px;
    filter: drop-shadow(0px 12px 50px rgba(0, 0, 0, 0.12));
    border: 1px solid rgba(28, 39, 76, 0.14);
    border-radius: 15px;
}

.production-chatbot-section-mobile{
    right: 0px;
    bottom: 70px;
    filter: drop-shadow(0px 12px 50px rgba(0, 0, 0, 0.12));
    border: 1px solid rgba(28, 39, 76, 0.14);
    border-radius: 15px;
}

.enter-your-message{
    border: none;
    outline: none;
    background: transparent;
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-size: normal;
    font-weight: 500;
    line-height: normal;
}

.enter-your-message-mobile{
    border: none;
    outline: none;
    background: transparent;
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 10px;
    font-size: normal;
    font-weight: 500;
    line-height: normal;
}