.clone-bot-btn{
    border-radius: 4px;
    background: #4529FA;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);

    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 16px;
}

.web-chat-default-text{
    color: var(--main-1-c-274-c, #1C274C);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.disable-text{
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.privacy:hover{
    color: var(--main-873-a-4363, #1C274C);
    text-decoration-line: underline;
    cursor: pointer;
}

.description{
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}

.description:hover{
    cursor: pointer;
    text-decoration-line: underline;
}

.sidebar-description{
    color: var(--main-54848-a-9-e, #848A9E);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.webbot-sidebar-item-default{
    padding: 13px 10px;
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.webbot-sidebar-item-default:hover{
    color: var(--main-873-a-4363, #1C274C);
    border-radius: 4px;
background: rgba(119, 109, 184, 0.12);
}

.webbot-sidebar-item-current{
    
    border-radius: 4px;
    background: rgba(69, 41, 250, 0.14);
    padding: 13px 10px;
}

.sidebar-how-text{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sidebar-how-text:hover{
    text-decoration: underline;
}

.create-app-btn{
    border-radius: 4px;
    border: 1px solid rgba(28, 39, 76, 0.14);
    background: #FFF;
    padding: 11px 20px;
    margin: 0px 16px;
}

.response-table{
    margin: 20px 0px;
    background-color: white;
    fill: #FFF;
    stroke-width: 1px;
    stroke: var(--main-12-e-4-e-5-ea, #E4E5EA);
    filter: drop-shadow(0px 2px 4px rgba(28, 39, 76, 0.07));
    border-radius: 10px;
}

.table-header{
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 16px;
}

.table-text{
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.table-item{
    padding: 12px 16px;
    border-bottom: 1px solid #EDEEF1;
}

.email-dialog{
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.email-dialog-form{
    width: 530px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 94px 0px rgba(28, 39, 76, 0.40);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.email-dialog-form h2{
    color: var(--main-1-c-274-c, #1C274C);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-top: 43px;
}

.email-dialog-form input{
    margin-top: 22px;
    margin-bottom: 22px;
    border-radius: 4px;
    border: 2px solid rgba(89, 80, 142, 0.20);
    background: #FFF;

    color: var(--main-1-c-274-c, #1C274C);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    width: 90%;
}

.email-dialog-form button{
    padding: 16px 0px;
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.bot-template{
    border-radius: 10px;
    
    /* border: 2px solid #4529FA;
    background: linear-gradient(0deg, rgba(69, 41, 250, 0.07) 0%, rgba(69, 41, 250, 0.07) 100%), #FFF; */
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
    /* width: 240px; */
    padding: 14px 12px;
    text-align: left;
    cursor: pointer;
    margin-bottom: 10px;
}

.bot-template-selected{
    border: 2px solid #4529FA;
    background: linear-gradient(0deg, rgba(69, 41, 250, 0.07) 0%, rgba(69, 41, 250, 0.07) 100%), #FFF;
}

.bot-template-unselected{
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
}

.home-webbot-textarea{
    margin-top: 43px;
    border-radius: 8px;
    border: 3px solid var(--yellow-ffe-0-b-3, #FFE0B3);
    background: #FFF;
}

.customize-bot-item{
    border-radius: 10px;
    

    
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    overflow: hidden;
    color: var(--main-1-c-274-c, #1C274C);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.customize-bot-item-selected{
    border: 2px solid #4529FA;
    background: rgba(69, 41, 250, 0.06);
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
}

.customize-bot-item-unselected{
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;
}

.continue-button{
    border-radius: 8px;
    background: #4529FA;
    box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);

    width: 171px;
    height: 49px;
    flex-shrink: 0;

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.customize-bot-section{
    border-radius: 10px;
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #FFF;

    padding: 24px 0px;
}

.recommendation-bot-header{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.recommendation-bot-desc{
    color: var(--main-873-a-4363, #3A4363);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.supported-models{
    margin-top: 8px;
    color: var(--main-873-a-4363, #3A4363);

    /* Regular Text */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-align: left;
    /* width: 1000px; */
    /* display: flex; */
    justify-content: start;
}

.display-bot-details{
    margin: 14px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
    background: #EFEDF6;

    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-align: left;
}

.webbot-title{
    color: var(--main-1-c-274-c, #1C274C);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-bottom: 8px;
}